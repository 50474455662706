.App {
    position: relative;
    min-height: 100vh;
}

.App::before {
    content: '';
    background-image: url('./assets/background.jpg');
    position: fixed; /* Change this to fixed positioning */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
}
