/* Style for the select element */
select {
    width: 100%; /* Adjust the width as needed */
    padding: 10px;
    border: 1px solid #ccc; /* Add a border */
    border-radius: 4px;
    background-color: #fff; /* Background color */
    color: #333; /* Text color */
    font-size: 16px;
    appearance: none; /* Remove default appearance (e.g., arrows) on some browsers */
    cursor: pointer; /* Show a pointer cursor on hover */
    margin-bottom: 1rem;
}

/* Style for the select when hovered */
select:hover {
    border-color: #007bff; /* Change border color on hover */
}

/* Style for the select when focused */
select:focus {
    outline: none; /* Remove the default focus outline */
    box-shadow: 0 0 5px #007bff; /* Add a shadow when focused */
}
